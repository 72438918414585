$primaryColor: #0078d4;
$transition: 0.3s ease;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
body {
	font-family: "Nunito", sans-serif;
}
a {
	text-decoration: none;
	transition: $transition;
}
.btn {
	&.primary {
		background-color: $primaryColor;
		border-color: $primaryColor;
		color: #fff;
		&:hover {
			background-color: rgba($color: $primaryColor, $alpha: 0.75);
			border-color: rgba($color: $primaryColor, $alpha: 0.75);
		}
	}
}
.main-layout {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	.layout-container {
		flex: 1 1 auto;
		display: flex;
		min-width: 0px;
		.side-nav {
			position: sticky;
			top: 0px;
			height: 100vh;
			width: 290px;
			min-width: 290px;
			background-color: $primaryColor;
			color: #fff;
			transition: $transition;
			.side-content {
				height: calc(100vh - 6rem);
				overflow-y: auto;
				padding: 1rem;
				.nav-title {
					margin-top: 1rem;
					margin-bottom: 0.5rem;
					padding-left: 1rem;
					padding-right: 1rem;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 12px;
				}
				ul {
					list-style: none;
					padding-left: 0;
					li {
						a,
						.btn-link {
							padding: 8px 16px;
							border-radius: 0.25rem;
							margin-bottom: 0.5rem;
							font-weight: 600;
							color: lightblue;
							font-size: 14px;
							display: flex;
							align-items: center;
							text-decoration: none;
							width: 100%;
							span {
								padding-left: 12px;
							}
							&.active {
								background-color: rgba($color: #fff, $alpha: 0.15);
								color: #fff;
							}
							i {
								width: 14px;
							}
							&:hover {
								background-color: rgba($color: #fff, $alpha: 0.15);
							}
						}
						&.dropdown {
							a,
							.btn-link {
								&.collapsed {
									background-color: transparent;
									color: lightblue;

									&.active,
									&:hover {
										background-color: rgba($color: #fff, $alpha: 0.15);
										color: #fff;
									}
								}
							}

							a,
							.btn-link {
								background-color: rgba($color: #fff, $alpha: 0.15);
								color: #fff;
								cursor: pointer;
							}
							.nav-dropdown {
								border-radius: 0.25rem;
								background-color: rgba($color: #fff, $alpha: 0.15);
								.nav-title {
									font-size: 12px;
									margin-top: 0;
									padding-top: 0.5rem;
								}
								li {
									a,
									.btn-link {
										margin-bottom: 0;
										background-color: transparent;
										&:first-of-type {
											padding-bottom: 1rem;
										}
										&::before {
											content: "";
											width: 16px;
											height: 1px;
											background-color: #fff;
											display: inline-block;
											position: relative;
											top: 0px;
										}
										&:hover {
											background-color: rgba($color: #fff, $alpha: 0.05);
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.main-content {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			position: relative;
			.header {
				position: sticky;
				top: 0px;
				z-index: 30;
				display: flex;
				width: 100%;
				border-bottom: 1px solid #dee2e6;
				background-color: #fff;
				.header-wrapper {
					position: relative;
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;
					padding: 0;
					padding-left: 1rem;
					padding-right: 1rem;
					height: 4rem;
					.header-action-btn {
						margin-left: 0.25rem;
						margin-right: 0.25rem;
						text-decoration: none;
						cursor: pointer;
						border-radius: 9999px;
						padding: 0.5rem;
						font-size: 26px;
						color: dimgray;
						line-height: 0;
						&.notifications {
							position: relative;
							&::after {
								content: "";
								position: absolute;
								top: 3px;
								right: 0px;
								width: 15px;
								height: 15px;
								background-color: red;
								border-radius: 50%;
							}
							span {
								font-size: 9px;
								color: #fff;
								position: absolute;
								top: 3px;
								right: 0px;
								z-index: 1;
								font-weight: 600;
								letter-spacing: 0;
								width: 15px;
								height: 15px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
						&.user {
							display: flex;
							align-items: center;
							font-size: 14px;
							font-weight: 600;
							img {
								width: 30px;
								height: 30px;
								border-radius: 50%;
								object-fit: cover;
								margin-right: 0.5rem;
								box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.15);
							}
							&::after {
								content: none;
							}
						}
					}
					.header-start,
					.header-end {
						display: flex;
						align-items: center;
					}
					.header-end {
						.dropdown-menu {
							box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
							border-color: #e5e7eb;
							transition: $transition;
							padding: 0;
							right: 0;
							.heading {
								padding: 1rem;
								line-height: 1.5;
								font-weight: 600;
								border-bottom: 1px solid #dee2e6;
							}
							&.notification-dropdown {
								width: 300px;
								max-height: 350px;
								overflow-y: auto;
							}
							li {
								display: flex;
								align-items: center;
								justify-content: flex-start;
								padding: 0.5rem 1rem;
								.dot {
									min-width: 10px;
									height: 10px;
									border-radius: 50%;
									background-color: $primaryColor;
								}
								.message {
									display: flex;
									flex-direction: column;
									font-size: 14px;
									padding-left: 1rem;
								}
								a,
								.btn-link {
									color: black;
									text-decoration: none;
									padding: 0;
									width: 100%;
									display: flex;
									align-items: center;
									&:active {
										background-color: inherit;
									}
								}
							}
						}
					}
				}
			}
			.content-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1 1 auto;
				height: 100%;
				.page-container {
					padding: 1.5rem 2rem 3rem 2rem;
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					height: 100%;
					gap: 1rem;
					.dashboard-card {
						background-color: #fff;
						position: relative;
						padding: 1.5rem 1rem;
						box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.15);
						transition: $transition;
						margin-bottom: 1.5rem;
						border-radius: 0.25rem;
						display: flex;
						flex-direction: column;
						gap: 1rem;
						color: #000000;
						.card-head {
							display: flex;
							align-items: center;
							font-weight: 600;
							p {
								flex-grow: 1;
								margin-bottom: 0;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								text-transform: uppercase;
								font-weight: 14px;
							}
							span {
								flex-shrink: 0;
								&.up {
									color: #0ab39c;
								}
								&.down {
									color: #f06548;
								}
							}
						}
						.card-body {
							display: flex;
							align-items: center;
							justify-content: space-between;
							h5 {
								font-size: 3rem;
								font-weight: 600;
								color: $primaryColor;
							}
							.icon {
								width: 3rem;
								height: 3rem;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: rgba($color: $primaryColor, $alpha: 0.15);
								color: $primaryColor;
								border-radius: 0.25rem;
								font-size: 1.25rem;
							}
						}
						&.card-2 {
							padding: 3rem;
							.card-body {
								flex-direction: column;
								align-items: center;
								justify-content: center;
								gap: 1rem;
								p {
									font-size: 1.25rem;
									font-weight: 600;
									text-align: center;
									text-transform: uppercase;
									margin-bottom: 0;
								}
							}
						}
						&:hover {
							transform: translateY(-5px);
						}
					}
					.widget {
						background-color: #fff;
						box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.15);
						font-size: 1.15rem;
						border-radius: 0.25rem;
						margin-bottom: 1.5rem;
						.widget-head {
							padding: 1rem;
							position: relative;
							border-bottom: 1px solid #dee2e6;
							&::before {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								width: 3px;
								height: 100%;
								background-color: $primaryColor;
							}
						}
						.widget-body {
							padding: 1rem;
						}
					}
					.table {
						font-size: 14px;
						.pos-selected-img {
							width: 50px;
							height: 50px;
							border-radius: 0.25rem;
							object-fit: cover;
						}
					}
					.form-control,
					.form-select {
						box-shadow: none;
						border-radius: 0.25rem;
						&:focus {
							border-color: $primaryColor;
						}
					}
					.form-control {
						&.quantity {
							width: 45px;
							display: inline-block;
							text-align: center;
							margin-left: 5px;
							font-size: 14px;
							line-height: 1;
						}
					}
					.form-label {
						margin-bottom: 0;
						font-size: 14px;
						font-weight: 600;
					}
					.was-validated {
						.form-control,
						.form-select {
							&:invalid {
								border-color: red;
							}
							&:valid {
								border-color: limegreen;
							}
						}
						.form-control {
							&:invalid {
								background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ff0000%27><circle cx=%276%27 cy=%276%27 r=%274.5%27/><path stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/><circle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ff0000%27 stroke=%27none%27/></svg>");
							}
							&:valid {
								background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2332cd32%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
							}
						}
						.form-select {
							&:invalid {
								--bs-form-select-bg-icon: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ff0000%27><circle cx=%276%27 cy=%276%27 r=%274.5%27/><path stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/><circle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ff0000%27 stroke=%27none%27/></svg>");
							}
							&:valid {
								--bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2332cd32%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
							}
						}
						.invalid-feedback {
							font-size: 14px;
							color: red;
						}
					}
					.btn-main {
						background-color: rgba($color: $primaryColor, $alpha: 0.15);
						border-color: transparent;
						color: $primaryColor;
						border-radius: 0.25rem;
						transition: $transition;
						i {
							padding-left: 0.5rem;
							font-size: 14px;
						}
						&:hover {
							background-color: rgba($color: $primaryColor, $alpha: 0.25);
						}
					}
					.step-arrow-nav {
						.nav {
							background-color: #f3f6f9;
							border: 0;
							.nav-link {
								border-radius: 0;
								position: relative;
								font-weight: 500;
								color: #000000;
								font-size: 14px;
								border: 0;
								&::before {
									content: "";
									position: absolute;
									border: 7px solid transparent;
									right: -14px;
									top: 50%;
									-webkit-transform: translateY(-50%);
									transform: translateY(-50%);
								}
								&.active {
									background-color: rgba($color: $primaryColor, $alpha: 0.1);
									color: $primaryColor;
									-webkit-box-shadow: none;
									box-shadow: none;
									&::before {
										border-left-color: rgba($color: $primaryColor, $alpha: 0.1);
									}
								}
								&.done {
									background-color: rgba($color: $primaryColor, $alpha: 0.09);
									color: $primaryColor;
								}
							}
							.nav-item {
								&:last-child {
									.nav-link {
										&::before {
											display: none;
										}
									}
								}
							}
						}
					}
					.pos-card {
						box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.15);
						transition: $transition;
						margin-bottom: 1.5rem;
						border-radius: 0.25rem;
						background-color: #fff;
						.image-container {
							img {
								height: 250px;
								object-fit: cover;
								border-top-left-radius: 0.25rem;
								border-top-right-radius: 0.25rem;
								width: 100%;
							}
						}
						.card-body {
							padding: 1rem;
							h6 {
								margin-bottom: 0;
								font-size: 1rem;
								font-weight: 600;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
						.card-footer {
							padding: 1rem;
							border-top: 1px dashed #dee2e6;
							display: flex;
							align-items: center;
							justify-content: space-between;
							p {
								margin-bottom: 0;
								font-size: 1.25rem;
								font-weight: 600;
							}
						}
						&:hover {
							transform: translateY(-5px);
						}
					}
					.table-card {
						position: sticky;
						top: 100px;
					}
					.pos-price {
						position: sticky;
						top: 100px;
					}
					.nav-tabs {
						.nav-item {
							.nav-link {
								color: gray;
								font-size: 16px;
								border: 0;
								border-bottom: 2px solid transparent;
								&.active {
									color: $primaryColor;
									border-bottom-color: $primaryColor;
								}
								&:hover {
									color: $primaryColor;
									border-bottom-color: rgba($color: $primaryColor, $alpha: 0.75);
								}
							}
						}
					}
					.msg-viewer {
						.msg-list {
							h6 {
								padding: 0 1.5rem;
								text-transform: uppercase;
								font-size: 11px;
								color: gray;
								font-weight: 700;
							}
							ul {
								list-style: none;
								padding-left: 0;
								max-height: 400px;
								overflow-y: auto;
								li {
									font-size: 14px;
									padding: 1rem 1.5rem;
									transition: 0.3s ease;
									&.msg-link {
										color: $primaryColor;
										cursor: pointer;
										&:hover,
										&.active {
											background-color: rgba($color: $primaryColor, $alpha: 0.09);
										}
									}
									&.msg-loader {
										height: 400px;
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}
							}
						}
						.msg-body {
							padding: 1rem;
							height: 100%;
							.no-msg {
								height: 400px;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								opacity: 0.5;
								text-align: center;
								i {
									font-size: 5rem;
								}
								span {
									margin-top: 1rem;
									text-transform: uppercase;
									font-size: 2rem;
								}
							}
						}
					}
					.pos-loader {
						height: 400px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $primaryColor;
					}
				}
			}
			.footer {
				position: absolute;
				bottom: 0;
				padding: 1rem;
				left: 0;
				right: 0;
				background-color: #fff;
				font-size: 14px;
				box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
			}
		}
	}
	&.toggled {
		.layout-container {
			.side-nav {
				width: 100px;
				min-width: 100px;
				.side-logo {
					img {
						max-width: 100%;
					}
				}
				.side-content {
					padding: 0.5rem;
					.nav-title {
						font-size: 8px;
						padding-left: 0;
					}

					ul {
						li {
							a,
							.btn-link {
								flex-direction: column;
								font-size: 11px;
								text-align: center;
								i {
									margin-bottom: 0.5rem;
								}
								span {
									padding-left: 0;
									line-height: 1;
								}
							}
							&.dropdown {
								.nav-dropdown {
									.nav-title {
										font-size: 8px;
										text-align: center;
										padding-right: 0;
									}
									li {
										a,
										.btn-link {
											&::before {
												top: -4px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.animate {
		animation-duration: 0.3s;
		-webkit-animation-duration: 0.3s;
		animation-fill-mode: both;
		-webkit-animation-fill-mode: both;
	}
}
@keyframes slideIn {
	0% {
		transform: translateY(1rem);
		opacity: 0;
	}

	100% {
		transform: translateY(0rem);
		opacity: 1;
	}

	0% {
		transform: translateY(1rem);
		opacity: 0;
	}
}

@-webkit-keyframes slideIn {
	0% {
		-webkit-transform: transform;
		-webkit-opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		-webkit-opacity: 1;
	}

	0% {
		-webkit-transform: translateY(1rem);
		-webkit-opacity: 0;
	}
}
.slideIn {
	-webkit-animation-name: slideIn;
	animation-name: slideIn;
}
.loader-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.with-login {
		height: 100vh;
	}
}
.loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: $primaryColor;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
		&:nth-child(1) {
			left: 8px;
			animation: loader1 0.6s infinite;
		}
		&:nth-child(2) {
			left: 8px;
			animation: loader2 0.6s infinite;
		}
		&:nth-child(3) {
			left: 32px;
			animation: loader2 0.6s infinite;
		}
		&:nth-child(4) {
			left: 56px;
			animation: loader3 0.6s infinite;
		}
	}
}
@keyframes loader1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes loader3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes loader2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
.toasts {
	display: table-cell;
	vertical-align: middle;
	position: fixed;
	right: 20px;
	top: 70px;
	z-index: 1;
	.toast {
		position: relative;
		top: 0;
		//right: -500px;
		z-index: 1000;
		border: 0;
		transition: 0.3s ease;
		margin-bottom: 1rem;
		&:not(.show) {
			display: block;
		}
		h6 {
			color: black;
		}
		i {
			font-size: 1.25rem;
			padding-right: 0.5rem;
		}
		&.success {
			.toast-body {
				color: green;
				border-bottom-color: green;
			}
		}
		&.fail {
			.toast-body {
				color: red;
				border-bottom-color: red;
			}
		}
		.toast-body {
			box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.15);
			background-color: #fff;
			border-bottom: 3px solid transparent;
			border-radius: 0.25rem;
			padding: 1rem;
		}
	}
	&.show {
		.toast {
			animation: toastAnimation;
			animation-duration: 0.5s;
			animation-delay: 0;
		}
	}
	&.hide {
		right: -500px;
	}
}
@keyframes toastAnimation {
	from {
		right: -500px;
	}
	to {
		right: 0;
	}
}
.p-paginator {
	.p-paginator-prev,
	.p-paginator-next,
	.p-paginator-first,
	.p-paginator-last {
		color: $primaryColor;
		&:focus {
			box-shadow: none;
		}
	}
	.p-paginator-prev,
	.p-paginator-next {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.p-paginator-pages {
		.p-paginator-page {
			margin-right: 0.5rem;
			color: $primaryColor;
			// &:first-child,
			&:last-child {
				margin: 0;
			}
			&.p-highlight {
				background-color: $primaryColor;
				border-color: $primaryColor;
			}
			&:focus {
				box-shadow: none;
			}
		}
	}
	.p-dropdown {
		&:not(.p-disabled) {
			&.p-focus {
				box-shadow: none;
				border-color: $primaryColor;
			}
		}
	}
}
.p-dropdown-panel {
	.p-dropdown-items {
		.p-dropdown-item {
			&.p-highlight {
				background-color: $primaryColor;
			}
		}
	}
}
.p-datatable {
	max-width: 1500px;
	.p-datatable-tbody {
		tr {
			td {
				white-space: wrap;
				max-width: 500px;
			}
		}
	}
}
